import React from "react";
import styled from "styled-components";
import ProgressBar from "../../../../../helpers/ProgressBar";
import {
  buttonIcon,
  nanodegreeCardBgImg,
} from "../../../../../../assets/icons/styep 3.0/icons";
import { Link } from "react-router-dom";

const InProgressCard = ({ progressData, selectedCard, type }) => {
  const {
    program: { slug, background_image, name, marketing_title, dashbord_type },
    completed_modules_count,
    total_modules_count,
    enroll_status,
  } = progressData;

  let completedModulesCount = completed_modules_count
    ? completed_modules_count
    : 0;
  let totalModulesCount = total_modules_count ? total_modules_count : 0;

  let dashboardType =
    dashbord_type === "day_by_day"
      ? completedModulesCount > 1
        ? "days"
        : "day"
      : completedModulesCount > 1
      ? "units"
      : "unit";

  const percentage = (completedModulesCount, totalModulesCount) => {
    if (totalModulesCount === 0) {
      return 0;
    }
    const progress = (completedModulesCount / totalModulesCount) * 100;

    return completedModulesCount === totalModulesCount
      ? 100
      : Math.min(progress, 99);
  };

  return (
    <>
      <ProgressContainer type={type}>
        <ImgContainer type={type}>
          <img src={background_image} alt="Banner_Image" />
        </ImgContainer>

        <Right>
          <DesContainer type={type}>
            <p>{name}</p>
            <h4>{marketing_title}</h4>
          </DesContainer>

          <Progress>
            <div
            // style={{
            //   display: enroll_status === "completed" ? "none" : "block",
            // }}
            >
              <ProgressBar
                value={percentage(completedModulesCount, totalModulesCount)}
                height={6}
              />
            </div>

            {enroll_status === "completed" && (
              <p>
                {/* {completedModulesCount}/{totalModulesCount} completed */}
                Congratulations! You can now view your program certificate.
              </p>
            )}
            {enroll_status === "ongoing" && (
              <p>
                {completedModulesCount} completed {dashboardType} out of{" "}
                {totalModulesCount} available
              </p>
            )}
          </Progress>

          {enroll_status === "completed" && (
            <ContinueButton to={`/nanodegree/${slug}/certification/`}>
              <span>
                View certificate <img src={buttonIcon} alt="icon" />
              </span>
            </ContinueButton>
          )}
          {enroll_status === "ongoing" && (
            <ContinueButton
              type={type}
              to={`/nanodegree/${slug}/daily-syllabus/`}
            >
              <span>
                Continue program{" "}
                <img
                  src={
                    type === "feed"
                      ? "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/03-09-2024/arrow-narrow-right-green.svg"
                      : buttonIcon
                  }
                  alt="icon"
                />
              </span>
            </ContinueButton>
          )}
        </Right>
      </ProgressContainer>
    </>
  );
};

export default InProgressCard;

const pxToRem = (px) => `${(px / 14).toFixed(2)}rem`;

const ProgressContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  max-height: 100%;
  background-color: #fcfcfd;
  border: 1.6px solid #e3e8ef;
  border-radius: 16px;
  padding: 16px;
  overflow: hidden;
  position: relative;
  ${({ type }) => type === "feed" && `flex-direction: column;`};
`;

const ImgContainer = styled.div`
  ${({ type }) => type === "feed" && `display: none;`};
  width: 298px;
  height: 167.63px;
  border-radius: 8px;
  background-color: #f4f4f4;
  overflow: hidden;
  flex-shrink: 0;

  ${({ type }) =>
    type &&
    `   @media (max-width: 1312px) {
    max-width: 200px;
    max-height: 150px
      }
      @media (max-width: 1023px) {
    max-width: 298px;
    max-height: 200px;
      }

      `};

  @media (max-width: 849px) {
    width: 30vw;
    height: 20vw;
  }
  @media (max-width: 649px) {
    width: 154px;
    height: 154px;
  }
  @media (max-width: 520px) {
    width: 80px;
    height: 80px;
  }
  @media (max-width: 374px) {
    display: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const DesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  h4 {
    flex-grow: 1;
    font-family: "gordita_medium" !important;
    font-size: ${pxToRem(16)};
    color: #202939;

    @media (max-width: 599px) {
      font-size: ${pxToRem(14)};
    }
  }

  p {
    font-family: "gordita_medium" !important;
    font-size: ${pxToRem(14)};
    color: ${({ type }) => (type === "feed" ? "#697586" : "#047853")};
  }
`;

const Progress = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  @media (max-width: 849px) {
    gap: 8px;
  }

  p {
    font-family: "gordita_regular";
    font-size: ${pxToRem(14)};
    color: #202939;

    @media (max-width: 599px) {
      font-size: ${pxToRem(12)};
    }
  }
`;

const ContinueButton = styled(Link)`
  width: fit-content;
  border: ${({ type }) => (type === "feed" ? "none" : "1px solid #059664")};
  border-radius: 10px;
  padding: ${({ type }) => (type === "feed" ? 0 : "8px 12px")};
  background-color: ${({ type }) =>
    type === "feed" ? "transparent" : "#059664"};
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  span {
    /* letter-spacing: 1px; */
    font-family: "gordita_regular";
    font-size: ${pxToRem(14)};
    color: ${({ type }) => (type === "feed" ? "#047853" : "white")};
    font-weight: ${({ type }) => (type === "feed" ? 600 : 500)};
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }

  &:hover {
    background-color: ${({ type }) =>
      type === "feed" ? "transparent" : "#06a46d"};

    transform: scale(1.03);
  }

  @media (max-width: 849px) {
    font-size: ${pxToRem(12)};
    padding: 6px 10px;
  }

  img {
    width: 20px;
    height: 20px;
  }
`;

const Right = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  flex-grow: 1;
  height: fit-content;
  @media (max-width: 849px) {
    gap: 8px;
  }
`;
