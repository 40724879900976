import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  bacgroundImgPrimary,
  gradHat,
  searchIcon,
} from "../../../../../../assets/icons/styep 3.0/icons";
import styled, { css, keyframes } from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { learnConfig } from "../../../../../../axiosConfig";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const QuerySection = ({
  searchCourse,
  setSearchCourse,
  isSearchActive,
  setSearchActive,
  setSearchInput,
  searchInput,
  setIsActive,
  isActive,
}) => {
  const history = useHistory();
  const user_data = useSelector((state) => state.user_data);
  const { access_token } = user_data;
  const [tagData, settagData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [skeletonsToLoad] = useState(Array(3).fill(null));
  const inputRef = useRef(null);

  const handleEnterKey = (e) => {
    if (e.keyCode === 13) {
      handleSearchBtnClick();
    }
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    setSearchCourse(searchInput);
  };

  const handleSearchBtnClick = () => {
    setSearchCourse(searchCourse);
  };

  const handleTagBtnClick = (data) => {
    if (isActive === data.pk) {
      setIsActive(null);
      setSearchCourse("");
    } else {
      setIsActive(data.pk);
      setSearchCourse(data.title);
    }
  };

  const fetchtagsTitle = async () => {
    setLoading(true);
    try {
      const { data } = await learnConfig.get(
        "/learn/list-nano-degree-programs/",
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
          params: {
            is_popular: true,
          },
        }
      );

      let { data: datas } = data;

      if (datas == undefined) {
        setLoading(false);
        settagData([
          { title: "Tech Degree" },
          { title: "Tech Grad" },
          { title: "Tech Schooling" },
        ]);
      } else {
        setLoading(false);
        settagData(datas.slice(0, 3));
      }
    } catch (error) {
      console.error("Error fetching tags:", error);
    }
  };

  useEffect(() => {
    fetchtagsTitle();
  }, []);

  useEffect(() => {
    setSearchCourse(searchInput);
  }, [searchInput]);

  useEffect(() => {
    // setPrgm(searchCourse);
    // setIsActive(null);

    if (searchCourse == null || searchCourse === "") {
      history.push("/nanodegree/");
    } else {
      history.push({
        pathname: "/nanodegree/",
        search: `?q=${searchCourse}`,
      });
    }
  }, [searchCourse]);

  useEffect(() => {
    if (isSearchActive && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isSearchActive]);

  return (
    <>
      <Query isSearchActive={isSearchActive}>
        <SearchContainer>
          <input
            ref={inputRef}
            type="text"
            maxLength="80"
            placeholder="Search for programs"
            value={searchInput}
            onChange={handleSearch}
            onKeyDown={handleEnterKey}
          />
          <button onClick={handleSearchBtnClick}>
            <ButtonIcon src={searchIcon} />
          </button>
        </SearchContainer>
        {isSearchActive ? null : (
          <>
            <FilterContainer isSearchActive={isSearchActive}>
              <PopularSearch>Popular search:</PopularSearch>

              <TagsContainer>
                {isLoading
                  ? skeletonsToLoad.map((_, i) => {
                      return (
                        <Skeleton
                          key={`skeleton-${i}`}
                          style={{
                            borderRadius: 16,
                            backgroundColor: "#eef2f6",
                          }}
                          width={100}
                          height={20}
                        />
                      );
                    })
                  : tagData.map((data) => {
                      return (
                        <Tag
                          key={data.pk}
                          isActive={isActive === data.pk}
                          onClick={() => handleTagBtnClick(data)}
                        >
                          {data.title}
                        </Tag>
                      );
                    })}
              </TagsContainer>
            </FilterContainer>
          </>
        )}
      </Query>
    </>
  );
};

export default QuerySection;

const pxToRem = (px) => `${(px / 14).toFixed(2)}rem`;

const Query = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ isSearchActive }) => (isSearchActive ? 0 : "16px")};
  justify-content: center;
  align-items: center;

  @media (max-width: 1023px) {
  }
`;

const SearchContainer = styled.div`
  min-width: 484px;
  display: flex;
  gap: 8px;
  align-items: center;
  padding-right: 5px;
  border: 2px solid #e3e8ef;
  background-color: #ffff;
  border-radius: 16px;
  overflow: hidden;
  transition: 2s;

  @media (max-width: 556px) {
    min-width: 260px;
    width: 90%;
    gap: 4px;
  }

  input {
    width: 100%;
    flex-grow: 1;
    background-color: #ffff;
    padding: 16px 6px 16px 24px;
    font-family: "gordita_regular";
    font-size: ${pxToRem(14)};

    @media (max-width: 556px) {
      max-width: 460px;
      padding: 16px;
    }

    @media (max-width: 370px) {
      padding: 12px 12px 12px 16px;
    }

    &::placeholder {
      color: #697586;
      font-family: "gordita_regular";
      font-size: ${pxToRem(14)};
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #059664;
    padding: 10px;
    border-radius: 12px;
    cursor: pointer;
    &:active {
      background-color: #067b52;
    }
  }
`;

const FilterContainer = styled.div`
  display: flex;
  transition: opacity 0.5s ease, max-height 0.5s ease;
  opacity: ${({ isSearchActive }) => (isSearchActive ? 0 : 1)};
  max-height: ${({ isSearchActive }) => (isSearchActive ? "0px" : "500px")};
  gap: 16px;
  justify-content: flex-start;
  flex-wrap: nowrap;

  overflow-x: auto;
  white-space: nowrap;

  @media (max-width: 556px) {
    width: 90%;
  }
`;

const PopularSearch = styled.p`
  color: #000000;
  font-family: "gordita_regular";
  font-size: ${pxToRem(12)};
  padding-top: 5px;
  white-space: nowrap;
`;

const TagsContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  /* flex-wrap: wrap; */
`;

const Tag = styled.button`
  padding: 5px 12px;
  font-size: ${pxToRem(12)};
  color: #475467;
  font-family: "gordita_medium";
  background-color: ${({ isActive, isPrgmSame }) => {
    return isActive || isPrgmSame ? "#CDD5DF" : "#eef2f6";
  }};

  border: 1px solid #cdd5df;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transform: scale(1.03);
  }
`;

const ButtonIcon = styled.img`
  @media (max-width: 370px) {
    width: 16px;
  }
`;
