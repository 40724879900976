import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styled from "styled-components";

const MyLearningCardSkeleton = ({ val }) => {
  return (
    <>
      <ProgressContainer val={val}>
        <div>
          <ImgContainer>
            <Skeleton width="100%" height="100%" />
          </ImgContainer>
        </div>
        <Right>
          <DesContainer>
            <Skeleton width={130} height={20} />
            <Skeleton width="100%" height={20} />
            <Skeleton width="100%" height={20} />
          </DesContainer>

          <Progress>
            <Skeleton width="100%" height={6} />
            <p>
              <Skeleton width="70%" height={20} />
            </p>
          </Progress>

          <ContinueButton>
            <Skeleton width={100} height={20} />
          </ContinueButton>
        </Right>
      </ProgressContainer>
    </>
  );
};

export default MyLearningCardSkeleton;

const ProgressContainer = styled.div`
  width: ${({ val }) => (val === "inprogresssection" ? "880px" : "100%")};

  display: flex;
  gap: 16px;
  background-color: #fcfcfd;
  border: 1.6px solid #e3e8ef;
  border-radius: 16px;
  padding: 16px;
  overflow: hidden;

  ${({ val }) =>
    val === "inprogresssection" &&
    `   @media (max-width: 1382px) {
    max-width: 50vw;
  }
      @media (max-width: 1023px) {
    max-width: 100%;
  }

`}
  @media (max-width: 374px) {
    gap: 0;
  }
`;

const ImgContainer = styled.div`
  /* max-width: ${({ val }) => (val ? "200px" : "298px")};
  max-height: ${({ val }) => (val ? "150px" : "200px")}; */

  width: 298px;
  height: 167.63px;
  border-radius: 8px;
  overflow: hidden;

  @media (max-width: 849px) {
    width: 30vw;
    height: 20vw;
  }
  @media (max-width: 649px) {
    width: 154px;
    height: 154px;
  }
  @media (max-width: 520px) {
    width: 80px;
    height: 80px;
  }
  @media (max-width: 374px) {
    display: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const DesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  h4 {
    @media (max-width: 599px) {
    }
  }

  p {
  }
`;

const Progress = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  @media (max-width: 849px) {
    gap: 8px;
  }

  p {
  }
`;

const ContinueButton = styled.div`
  width: fit-content;
  justify-content: center;
  align-items: center;
  border-radius: 10px;

  @media (max-width: 849px) {
  }
`;

const Right = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  flex-grow: 1;
  height: fit-content;
  @media (max-width: 849px) {
    gap: 12px;
  }
`;
