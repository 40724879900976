import React from "react";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import styled from "styled-components";

const ProgramCardSkeleton = () => {
  return (
    <>
      <MainCardContainer>
          <ImgContainer>
            <Skeleton width="100%" height={167.63} />
          </ImgContainer>

        <Right>
          <TypeContainer>
            <Skeleton width={80} height={20} />
          </TypeContainer>

          <Title>
            <Skeleton width="100%" height={20} />
          </Title>
          <Title>
            <Skeleton width="100%" height={20} />
          </Title>

          <ProgramName>
            <Skeleton width="100%" height={15} />
          </ProgramName>

          <TagsContainer>
            <Tag>
              <Skeleton width={120} height={15} />
            </Tag>
            <Tag>
              <Skeleton width={120} height={15} />
            </Tag>
          </TagsContainer>
        </Right>
      </MainCardContainer>

    </>
  );
};

export default ProgramCardSkeleton;


const MainCardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1.6px solid #e3e8ef;
  background-color: #fcfcfd;
  border-radius: 16px;
  padding: 10px 10px 16px 10px;
  overflow: hidden;

  @media (max-width: 1023px) {
    flex-direction: row;
  }
`;

const ImgContainer = styled.div`
  flex-shrink: 0;
  border-radius: 8px;
  width: 100%;
  height: 167.63px;
  overflow: hidden;
  @media (max-width: 1023px) {
    width: 80px;
    height: 80px;
  }
  @media (max-width: 649px) {
    width: 154px;
    height: 154px;
  }
  @media (max-width: 599px) {
    width: 80px;
    height: 80px;
  }
`;

const Right = styled.div`
    width: 100%;
    height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 8px;
  padding-right: 8px;
`;

const TypeContainer = styled.div`
  width: 80px;
`;

const Title = styled.div`
  width: 100%;
`;

const ProgramName = styled.div`
  width: 40%;
`;

const TagsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;

  @media (max-width: 1439px), (max-width: 889px), (max-width: 449px) {
    flex-direction: column;
  }
`;

const Tag = styled.div`
  width: fit-content;
  display: flex;
  gap: 4px;
`;
