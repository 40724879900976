import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { bacgroundImgPrimary } from "../../../../../../assets/icons/styep 3.0/icons";
import QuerySection from "./QuerySection";

const NanoDegreeSpotlight = ({
  searchCourse,
  setSearchCourse,
  isSearchActive,
  setSearchActive,
  setSearchInput,
  searchInput,
  setIsActive,
  isActive,
}) => {
  const [isGridChanged, setIsGridChanged] = useState(false);

  const handleSearchActiveChange = () => {
    setIsGridChanged(isSearchActive);
  };

  useEffect(() => {
    handleSearchActiveChange();
  }, [isSearchActive]);

  return (
      <ImageWrapper isSearchActive={isSearchActive}>
        <Wrapper isSearchActive={isSearchActive}>
          <ContentWrapper isSearchActive={isGridChanged}>
            <DesContainer isSearchActive={isSearchActive}>
              <h1>
                Explore <span>NanoDegree</span>
              </h1>
              <p>Explore Curated Technology Courses and More</p>
            </DesContainer>

            <QuerySection
              searchCourse={searchCourse}
              setSearchCourse={setSearchCourse}
              isSearchActive={isSearchActive}
              setSearchActive={setSearchActive}
              setSearchInput={setSearchInput}
              searchInput={searchInput}
              setIsActive={setIsActive}
              isActive={isActive}
            />
          </ContentWrapper>
        </Wrapper>
      </ImageWrapper>
  );
};

export default NanoDegreeSpotlight;

const pxToRem = (px) => `${(px / 14).toFixed(2)}rem`;


const slideIn = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  z-index: 0;
  border-radius: 12px;
  background-color: #e3e3e3;
  background-image: url(${bacgroundImgPrimary});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  padding: 55px 20px 54px 20px;
  width: 100%;
  animation: ${({ isSearchActive }) => (isSearchActive ? slideIn : "none")} 0.5s
    ease forwards;

  @media (max-width: 1023px) {
    padding: 56.5px 0 55.5px 0;
  }
`;

const Wrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 1023px) {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  gap: 32px;
  justify-content: center;
  align-items: center;
  grid-template-columns: ${({ isSearchActive }) => (isSearchActive ? "1fr 1fr" : "1fr")};

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width:100%;
  }
`;

const DesContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  display: flex;
  gap: ${({ isSearchActive }) => (isSearchActive ? 0 : "16px")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (max-width: 599px) {
    width: 90%;
  }

  h1 {
    font-family: "gordita_medium";
    align-items: center;
    font-size: ${({ isSearchActive }) =>
      isSearchActive ? pxToRem(32) : pxToRem(42)};
    font-weight: 500;
    color: #000000;

    @media (max-width: 1023px) {
      font-size: ${pxToRem(32)};
    }

    @media (max-width: 410px) {
      font-size: ${pxToRem(24)};
    }

    span {
      font-family: "gordita_medium";
      font-size: ${({ isSearchActive }) =>
        isSearchActive ? pxToRem(32) : pxToRem(42)};
      font-weight: 500;
      color: #059664;

      @media (max-width: 1023px) {
        font-size: ${pxToRem(32)};
      }

      @media (max-width: 410px) {
        font-size: ${pxToRem(24)};
      }
    }
  }

  p {
    transition: opacity 0.5s ease, max-height 0.5s ease;
    opacity: ${({ isSearchActive }) => (isSearchActive ? 0 : 1)};
    max-height: ${({ isSearchActive }) => (isSearchActive ? "0px" : "200px")};
    overflow: hidden;
    font-family: "gordita_regular";
    font-size: ${pxToRem(16)};
    color: #000000;

    @media (max-width: 410px) {
      font-size: ${pxToRem(14)};
    }
  }
`;

