import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { NavLink, useHistory } from "react-router-dom";
import ProgressCard from "./InProgressCard";
import { progressDatas } from "../test";
import {
  buttonLeftArrow,
  buttonRightArrow,
  greenButtonNarrowRight,
  inProgressBg,
  whiteButtonNarrowRight,
} from "../../../../../../assets/icons/styep 3.0/icons";
import InProgressCard from "./InProgressCard";
import Slider from "react-slick";
import CardSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MyLearningCardSkeleton from "../skeletons/MyLearningCardSkeleton";

const InProgressSection = ({
  isSearchActive,
  myLearningData,
  isLoading,
  statusCode,
  searchCourse,
}) => {
  const history = useHistory();
  const [progressCardDatas, setProgressCardDatas] = useState(myLearningData);
  const [selectedCard, setCard] = useState(0);
  const [skeletonsToLoad] = useState(Array(1).fill(null));
  let slider = useRef();


  const next = () => {
    slider.slickNext();
  };

  const previous = () => {
    slider.slickPrev();
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
  };

  useEffect(() => {
    setProgressCardDatas(myLearningData);
  }, [myLearningData]);

  return (
    <>
      <Image
        isSearchActive={isSearchActive}
        statusCode={statusCode}
        searchCourse={searchCourse}
      >
        <ProgressContainer>
          <DesContainer>
            <div>
              <h2>My learnings</h2>
              <p>Find programs you own and continue from where you left</p>
            </div>

            <SinglePageBT to="/nanodegree/mylearnings" >
              See all my programs <img src={greenButtonNarrowRight} alt="arrow-icon" />
            </SinglePageBT>
          </DesContainer>

          <CardContainer>
            <Button onClick={previous} progressCardDatas={progressCardDatas}>
              <img src={buttonLeftArrow} alt="button-left-arrow" />
            </Button>

            <SliderContainer>
              {isLoading
                ? skeletonsToLoad.map((_, i) => {
                    return (
                      <MyLearningCardSkeleton
                        val={"inprogresssection"}
                        key={`skeleton-${i}`}
                      />
                    );
                  })
                : progressCardDatas.length > 0 && (
                    <SliderWrapper
                      {...settings}
                      ref={(c) => {
                        slider = c;
                      }}
                    >
                      {progressCardDatas.map((progressData, i) => {
                        return (
                          <InProgressCard
                            key={i}
                            val={"inprogresssection"}
                            progressData={progressData}
                          />
                        );
                      })}
                    </SliderWrapper>
                  )}
            </SliderContainer>

            <Button onClick={next} progressCardDatas={progressCardDatas}>
              <img src={buttonRightArrow} alt="" />
            </Button>
          </CardContainer>
        </ProgressContainer>
      </Image>
    </>
  );
};

export default InProgressSection;

const pxToRem = (px) => `${(px / 14).toFixed(2)}rem`;

const Image = styled.div`
  ${({ statusCode }) => (!statusCode === 6000 ? "display:none" : "")}
  position:relative;
  width: 100vw;
  left: 50%;
  transform: translateX(-50vw);

  transition: opacity 0.5s, max-height 0.5s;
  opacity: ${({ searchCourse }) => (searchCourse.length >= 1 ? 0 : 1)};
  max-height: ${({ searchCourse }) => (searchCourse.length >= 1 ? 0 : "100%")};

  background-image: url(${inProgressBg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
`;

const SliderWrapper = styled(CardSlider)`
  .slick-track {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .slick-dots li button::before {
    font-size: 10px !important;
    margin: 2px;
  }
  .slick-dots li.slick-active button::before {
    font-size: 16px !important;
  }
`;

const ProgressContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  padding: 59px 48px;
  max-width: 1440px;

  @media (max-width: 1023px) {
    flex-direction: column;
    padding: 26px 16px 46px 16px;
  }
`;

const DesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 18px;
  flex-shrink: 0;

  @media (max-width: 1023px) {
    flex-direction: row;
    justify-content: space-between;
  }

  @media (max-width: 674px) {
    flex-direction: column;
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 8px;

    h2 {
      font-family: "gordita_medium";
      font-size: ${pxToRem(24)};
      color: #202939;

      @media (max-width: 1023px) {
        font-size: ${pxToRem(20)};
      }
    }
    p {
      font-family: "gordita_regular";
      font-size: ${pxToRem(16)};
      color: #202939;
      max-width: 264px;

      @media (max-width: 1023px) {
        max-width: 100%;
      }
    }
  }
`;

const SinglePageBT = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding-right: 7px;
  width: fit-content;
  font-family: "gordita_medium";
  font-size: ${pxToRem(14)};
  color: #047853;
  cursor: pointer;
  flex-shrink: 0;
  transition: all 0.3s ease-in-out;
  @media (max-width: 1023px) {
    align-items: flex-end;
  }

  &:hover {
    color: #059664;
    transform: scale(1.03) translateX(10px);
    @media (max-width: 1023px) {
      transform: translateX(7px);
    }
  }
`;

const CardContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;

  @media (max-width: 1023px) {
    justify-content: center;
  }
`;

const SliderContainer = styled.div`
  max-width: 880px;
  width: 100%;
  @media (max-width: 1382px) {
    max-width: 60vw;
  }
  @media (max-width: 1023px) {
    max-width: 100%;
  }
`;

const Button = styled.button`
  ${({ progressCardDatas }) =>
    progressCardDatas.length < 2 ? "display:none" : ""}

  border: 1px solid #e3e8ef;
  padding: 7px;
  background-color: #f7fffb;
  border-radius: 50%;
  cursor: pointer;
  @media (max-width: 1023px) {
    display: none;
  }

  &:active {
    background-color: #e1f8ed;
  }
  img {
    display: block;
  }
`;
