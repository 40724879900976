import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import NanoDegreeCard from "../components/program-card/NanoDegreeCard.jsx";
import { learnConfig } from "../../../../../axiosConfig.js";
import { useSelector } from "react-redux";
import NanoDegreeSpotlight from "../components/spotlight/NanoDegreeSpotlight.jsx";
import CardSkeleton from "../components/skeletons/ProgramCardSkeleton.jsx";
import InProgressSection from "../components/in-progress/InProgressSection.jsx";
import TalropEdtechHelmet from "../../../../helpers/TalropEdtechHelmet.jsx";

const NanoDegreeMainPage = () => {
  const user_data = useSelector((state) => state.user_data);
  const { access_token } = user_data;
  const [cardData, setCardData] = useState([]);
  const [myLearningData, setMyLearningData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [isActive, setIsActive] = useState(null);
  const [searchCourse, setSearchCourse] = useState("");
  const [cardStatusCode, setCardStatusCode] = useState(6000);
  const [mLstatusCode, setMLStatusCode] = useState(6001);
  const [isLoading, setLoading] = useState(false);
  const [isSearchActive, setSearchActive] = useState(false);
  const [skeletonsToLoad] = useState(Array(8).fill(null));

  const getFilteredDatas = (program_type_choice) => {
    return cardData.filter(
      (data) => data?.program_type_choice === program_type_choice
    );
  };

  const fetchPrgmCardsData = async (type) => {
    if (searchInput) {
      setSearchActive(true);
      setIsActive(false);
    } else {
      setSearchActive(false);
    }

    setLoading(true);
    try {
      const { data } = await learnConfig.get(
        `/learn/list-nano-degree-programs/?q=${searchInput}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      let { data: datas, status_code } = data;

      if (status_code !== 6000) {
        setCardData([]);
        setLoading(false);
        setCardStatusCode(status_code);
      } else {
        setCardData(datas);
        setLoading(false);
        setCardStatusCode(status_code);
      }
    } catch (error) {
      console.error("Error fetching cards:", error);
    }
  };

  const fetchMyLrngsCardsData = async () => {
    setLoading(true);
    try {
      const { data } = await learnConfig.get(
        "/learn/nano-degree-my-learning-progress/",
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      let { data: datas, status_code } = data;

      if (status_code !== 6000) {
        setMyLearningData([]);
        setLoading(false);
        setMLStatusCode(status_code);
      } else {
        setMyLearningData(datas);
        setLoading(false);
        setMLStatusCode(status_code);
      }
    } catch (error) {
      console.error("Error fetching Mylearnings data:", error);
    }
  };

  useEffect(() => {
    fetchMyLrngsCardsData();
  }, []);

  useEffect(() => {
    document.documentElement.classList.remove("modal-enabled");
  }, []);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      fetchPrgmCardsData("for_school_students");
    }, 400);

    return () => clearTimeout(debounceTimeout);
  }, [searchInput, searchCourse]);

  return (
    <>
      <TalropEdtechHelmet title="NanoDegree" />

      <MainContainer searchCourse={searchCourse}>
        <NanoDegreeSpotlight
          searchCourse={searchCourse}
          setSearchCourse={setSearchCourse}
          isSearchActive={isSearchActive}
          setSearchActive={setSearchActive}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          setIsActive={setIsActive}
          isActive={isActive}
        />
        {myLearningData.length > 0 && (
          <InProgressSection
            searchCourse={searchCourse}
            isSearchActive={isSearchActive}
            myLearningData={myLearningData}
            isLoading={isLoading}
            statusCode={mLstatusCode}
          />
        )}

        <CardContainer
          isSearchActive={isSearchActive}
          statusCode={mLstatusCode}
          searchCourse={searchCourse}
        >
          <Heading>
            {cardStatusCode === 6000 ? (
              searchCourse.trim() && (
                <>
                  Search result for <span>{`'${searchCourse}'`}</span>
                </>
              )
            ) : searchCourse.length === 0 ? (
              <>No Data Found</>
            ) : (
              <>
                No Data Found for <span>{`'${searchCourse}'`}</span>
              </>
            )}
          </Heading>

          {isLoading ? (
            <Cards>
              {skeletonsToLoad.map((_, i) => {
                return <CardSkeleton key={`skeleton-${i}`} />;
              })}
            </Cards>
          ) : cardStatusCode === 6000 ? (
            <>
              {searchInput ? (
                <>
                  <ProgramBox>
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                        }}
                      >
                        {!searchCourse && (
                          <>
                            <Heading>Program for School Students</Heading>
                          </>
                        )}
                        <Cards>
                          {cardData?.map((data) => {
                            return (
                              <NanoDegreeCard key={data.pk} cardDatas={data} />
                            );
                          })}
                        </Cards>
                      </div>
                    </>
                  </ProgramBox>
                </>
              ) : (
                <>
                  <ProgramBox>
                    {getFilteredDatas("for_school_students").length > 0 && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                          }}
                        >
                          {!searchCourse && (
                            <>
                              <Heading>Program for School Students</Heading>
                            </>
                          )}
                          <Cards>
                            {getFilteredDatas("for_school_students")?.map(
                              (data) => {
                                return (
                                  <NanoDegreeCard
                                    key={data.pk}
                                    cardDatas={data}
                                  />
                                );
                              }
                            )}
                          </Cards>
                        </div>
                      </>
                    )}
                    {getFilteredDatas("for_college_students").length > 0 && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                          }}
                        >
                          {!searchCourse && (
                            <>
                              <Heading>Program for college students</Heading>
                            </>
                          )}
                          <Cards>
                            {getFilteredDatas("for_college_students")?.map(
                              (data) => {
                                return (
                                  <NanoDegreeCard
                                    key={data.pk}
                                    cardDatas={data}
                                  />
                                );
                              }
                            )}
                          </Cards>
                        </div>
                      </>
                    )}
                    {getFilteredDatas("for_graduates").length > 0 && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                          }}
                        >
                          {!searchCourse && (
                            <>
                              <Heading>Program for graduates</Heading>
                            </>
                          )}
                          <Cards>
                            {getFilteredDatas("for_graduates")?.map((data) => {
                              return (
                                <NanoDegreeCard
                                  key={data.pk}
                                  cardDatas={data}
                                />
                              );
                            })}
                          </Cards>
                        </div>
                      </>
                    )}
                    {getFilteredDatas("12th_graduates").length > 0 && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                          }}
                        >
                          {!searchCourse && (
                            <>
                              <Heading>Program for 12th graduates</Heading>
                            </>
                          )}
                          <Cards>
                            {getFilteredDatas("12th_graduates")?.map((data) => {
                              return (
                                <NanoDegreeCard
                                  key={data.pk}
                                  cardDatas={data}
                                />
                              );
                            })}
                          </Cards>
                        </div>
                      </>
                    )}
                  </ProgramBox>
                </>
              )}
            </>
          ) : (
            <NoDataFound>
              <img
                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/04-11-2024/nomatch.svg"
                alt="Empty-state"
              />
            </NoDataFound>
          )}
        </CardContainer>
      </MainContainer>
    </>
  );
};

export default NanoDegreeMainPage;

const pxToRem = (px) => `${(px / 14).toFixed(2)}rem`;

const NoDataFound = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
  border-radius: 8px;
  padding: 20px;
  overflow: hidden;
  min-height: 50vh;

  img {
    width: 25%;
    height: 25%;
    display: block;

    @media (max-width: 767px) {
      width: 50%;
      height: 50%;
    }
  }

  h5 {
    font-family: "gordita_medium";
    font-size: ${pxToRem(14)};
    color: #475467;
    text-align: center;
  }
`;

const MainContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  ${({ searchCourse }) => (searchCourse.length > 0 ? "gap:24px" : "gap:48px")}

  max-width: 1440px;
  padding: 104px 48px 48px 48px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1023px) {
    padding: 104px 16px 48px 16px;
  }

  @media (max-width: 768px) {
    padding: 80px 16px 24px 16px;
  }
`;

const Heading = styled.h2`
  font-family: "gordita_medium";
  font-size: ${pxToRem(24)};
  color: #202939;
  text-transform: capitalize;
  white-space: nowrap; /* Keeps the "No Data Found for" on one line */

  span {
    white-space: normal;
    word-break: break-all;
    font-family: "gordita_medium";
    font-size: ${pxToRem(24)};
    color: #202939;

    @media (max-width: 1023px) {
      font-size: ${pxToRem(20)};
    }
  }
  @media (max-width: 1023px) {
    font-size: ${pxToRem(20)};
  }
`;

const CardContainer = styled.div`
  /* padding-top: ${({ searchCourse, statusCode }) =>
    searchCourse.length >= 1 || statusCode !== 6000 ? 0 : "38px"}; */
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
  word-break: break-word;

  @media (max-width: 1023px) {
    gap: 18px;
  }
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  width: 100%;
  justify-items: center;

  @media (max-width: 1248px) {
    gap: 16px;
  }
  @media (max-width: 1023px) {
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
  }

  @media (max-width: 649px) {
    gap: 18px;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
  }
`;

const ProgramBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 38px;
`;
