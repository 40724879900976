import React, { useEffect, useState } from "react";
import styled from "styled-components";
import img from "../../utils/istockphoto-1399611777-612x612.jpg";
import img2 from "../../utils/istockphoto-158879321-612x612.jpg";
import { purchasedPeople } from "../test";
import Jdenticon from "react-jdenticon";

const MutualFriends = ({ people_who_purchased }) => {


  
  if (!people_who_purchased) {
    people_who_purchased = [];
  }
  const [frndsDatas, setFrndsDatas] = useState([]);
  const [isHover, setHover] = useState(null);
  const [isSame, setSame] = useState(false);

  const handleData = () => {
    people_who_purchased.length >=3 &&
      setFrndsDatas(people_who_purchased.slice(0, 3));
  };

  const handleMouseEnter = (data) => {
    setHover(data.pk);
  };

  const handleMouseLeave = () => {
    setHover(null);
  };

  useEffect(() => {
    handleData();
  }, []);

  

  return (
    <>
      <Container>
        {frndsDatas.map((data) => {
          return (
            <ImgContainerWithToolTip key={data?.pk}>
              {isHover === data.pk && (
                <ToolTip>
                  <p>{data?.name} and {people_who_purchased.length-1}+ others have purchased this course</p>
                </ToolTip>
              )}
              {/* <ToolTip>{data?.username}</ToolTip> */}
              <MFContainer
                onMouseEnter={() => handleMouseEnter(data)}
                onMouseLeave={() => handleMouseLeave()}
              >
                {data.photo ? (
                  <img src={data?.photo} alt="Friend" />
                ) : (
                  <Jdenticon value={data.name} />
                )}
              </MFContainer>
            </ImgContainerWithToolTip>
          );
        })}
      </Container>
    </>
  );
};

export default MutualFriends;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const MFContainer = styled.div`
  background-color: white;
  width: 1.7rem;
  height: 1.7rem;
  border: 2px solid white;
  margin-right: -6px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  position: relative;

  &:hover {
    z-index: 10;
    transform: scale(1.3);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
`;

const ImgContainerWithToolTip = styled.div`
  position: relative;
`;

const ToolTip = styled.span`
  display: block;
  position: absolute;
  min-width: 200px;
  max-width: 200px;
  height: fit-content;
  background-color: #1e1e1e;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  z-index: 10;
  bottom: 140%;
  right: -100%;
  transition: 300ms all ease-in;
  p {
    font-family: "gordita_regular";
    font-size: 12px;

    background-color: #1e1e1e;
    color: #fff;

    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
  }
  @media (max-width: 1023px) {
    right: -100%;
    bottom: 130%;
  }

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    right: 10%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #1e1e1e transparent transparent transparent;
  }
`;

// display: ${({ isHover }) => (isHover ? "block" : "none")};
/* display: ${({ isHover, pk }) => (isHover === pk ? "block" : "none")}; */
