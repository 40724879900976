import React from "react";
import styled from "styled-components";

const ProgramType = ({ type }) => {
  let lowecaseType = type.toLowerCase();

  const capitalizeFirstLetter = (type) => {
    return type[0].toUpperCase() + type.slice(1).toLowerCase();
  };

  return (
    <>
      {type && (
        <ClassTypeContainer type={type}>
          <Marker type={lowecaseType} />
          <State type={lowecaseType}>{capitalizeFirstLetter(type)}</State>
        </ClassTypeContainer>
      )}
    </>
  );
};

export default ProgramType;

const pxToRem = (px) => `${(px / 14).toFixed(2)}rem`;

const ClassTypeContainer = styled.div`
  display: flex;
  width: fit-content;
  border: 1px solid
    ${({ type }) => {
      return type === "online"
        ? "#D9D6FE"
        : type === "offline"
        ? "#FCCEEE"
        : "#F9DBAF";
    }};
  border-radius: 16px;
  background-color: ${({ type }) => {
    return type === "online"
      ? "#F4F3FF"
      : type === "offline"
      ? "#FDF2FA"
      : "#FEF6EE";
  }};
  padding: 2px 8px 2px 6px;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const Marker = styled.div`
  width: 6px;
  height: 6px;
  background-color: ${({ type }) => {
    return type === "online"
      ? "#7A5AF8"
      : type === "offline"
      ? "#EE46BC"
      : "#EF6820";
  }};
  border-radius: 50%;
`;

const State = styled.p`
  font-family: "gordita_medium" !important;
  font-size: ${pxToRem(12)};
  color: ${({ type }) => {
    return type === "online"
      ? "#5925DC"
      : type === "offline"
      ? "#C11574"
      : "#B93815";
  }};

  @media (max-width: 426px) {
    font-size: ${pxToRem(13)};
  }
`;
