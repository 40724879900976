import React, { lazy, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import {
  durationIcon,
  educationIcon,
  nanodegreeCardBgImg,
} from "../../../../../../assets/icons/styep 3.0/icons";
import ProgramType from "./ProgramType";
import MutualFriends from "./MutualFriends";
import { NavLink } from "react-router-dom";

const NanoDegreeCard = ({ cardDatas }) => {
  let {
    background_image,
    marketing_description,
    marketing_title,
    pk,
    program_duration,
    session_type,
    slug,
    title,
    program_type,
    people_who_purchased,
  } = cardDatas;
  const titleRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    if (titleRef.current) {
      const isOverflowing =
        titleRef.current.scrollHeight > titleRef.current.clientHeight;
      setIsTruncated(isOverflowing);
    }
  }, [titleRef.current, marketing_title]);

  return (
    <>
      <MainCardContainer to={`/nanodegree/${slug}`}>
        <div>
          <ImgContainer>
            <img src={background_image} alt="Image_Banner" />
          </ImgContainer>
        </div>

        <Right>
          <TypeContainer>
            <ProgramType type={session_type} />
            <MutualFriends people_who_purchased={people_who_purchased} />
          </TypeContainer>

          <TitleWrapper
            isTruncated={isTruncated}
            data-fulltext={isTruncated && marketing_title}
          >
            <Title ref={titleRef}>{marketing_title}</Title>
          </TitleWrapper>
          <div
            style={{ display: "flex", gap: "10px", flexDirection: "column" }}
          >
            <ProgramName>{title}</ProgramName>

            <TagsContainer>
              {program_type && (
                <Tag>
                  <img src={educationIcon} alt="Education" />
                  {program_type}
                </Tag>
              )}
              {program_duration && (
                <Tag>
                  <img src={durationIcon} alt="Duration" />

                  {program_duration}
                </Tag>
              )}
            </TagsContainer>
          </div>
        </Right>
      </MainCardContainer>
    </>
  );
};

export default NanoDegreeCard;

const pxToRem = (px) => `${(px / 14).toFixed(2)}rem`;

const MainCardContainer = styled(NavLink)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1.6px solid #e3e8ef;
  background-color: #fcfcfd;
  border-radius: 16px;
  padding: 10px 10px 16px 10px;
  /* overflow: hidden; */
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  text-decoration: none;
  color: inherit;

  &:hover {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    transform: scale(1.02) translateY(-4px);
    z-index: 998;
  }

  @media (max-width: 1023px) {
    flex-direction: row;
  }
`;

const ImgContainer = styled.div`
  border-radius: 8px;
  position: relative;
  width: 100%;
  height: 167.63px;
  overflow: hidden;
  @media (max-width: 1023px) {
    width: 80px;
    height: 80px;
  }
  @media (max-width: 649px) {
    width: 154px;
    height: 154px;
  }
  @media (max-width: 599px) {
    width: 80px;
    height: 80px;
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
`;

const Right = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 8px;
  padding-right: 8px;

`;

const TypeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleWrapper = styled.div`
    ${({ isTruncated }) =>
    isTruncated &&
    `
      &:hover::after {
    content: attr(data-fulltext);
    position: absolute;
    left: 20%;
    bottom: 75%;
    width: max-content;
    max-width: 200px;
    background-color: rgba(0, 0, 0, 0.8);
    font-family: "gordita_regular";
    font-size: ${pxToRem(12)};
    color: #fff;
    padding: 8px;
    border-radius: 4px;
    white-space: normal;
    z-index: 9999;

    @media (max-width: 375px) {
      left: 0;
    }
  }


`}

`;

const Title = styled.h4`
  flex-grow: 1;
  font-family: "gordita_medium";
  font-size: ${pxToRem(16)};
  color: #202939;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 1023px) {
    font-size: ${pxToRem(14)};
  }
`;

const ProgramName = styled.p`
  font-family: "gordita_medium" !important;
  font-size: ${pxToRem(14)};
  color: #047853;
`;

const TagsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  & > :first-child {
    justify-content: flex-start;
  }

  & > :last-child {
    min-width: 85px;
    justify-content: flex-end;
  }

  @media (max-width: 1315px) {
    flex-direction: column;
    & > :last-child {
      justify-content: flex-start;
    }
  }

  @media (max-width: 1023px) {
    flex-direction: row;
    & > :last-child {
      justify-content: flex-end;
    }
  }

  @media (max-width: 889px) {
    flex-direction: column;
    & > :last-child {
      justify-content: flex-start;
    }
  }
  @media (max-width: 649px) {
    flex-direction: row;
    & > :last-child {
      justify-content: flex-end;
    }
  }

  @media (max-width: 449px) {
    flex-direction: column;
    & > :last-child {
      justify-content: flex-start;
    }
  }
`;

const Tag = styled.div`
  width: fit-content;
  font-family: "gordita_regular";
  font-size: ${pxToRem(12)};
  color: #202939;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  img {
  }
  @media (max-width: 1439px) {
    justify-content: flex-start;
  }
`;
